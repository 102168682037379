<template>
  <div class="md-course-detail-pages-component">
    <!-- 课程信息 -->
    <div class="course-info-box">
      <div class="course-info-con">
        <div class="menu-box">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <!-- <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item> -->
            <el-breadcrumb-item :to="{ path: '/category' }">酷学课程</el-breadcrumb-item>
            <el-breadcrumb-item>{{ courseDetailInfo.title }}</el-breadcrumb-item>
          </el-breadcrumb>

        </div>
        <p class="course-title">{{ courseDetailInfo.title }}</p>
        <div class="teacher-box" v-if="courseDetailInfo.lecturer">
          <div class="t-info">
            <span>主讲：{{ courseDetailInfo.lecturer }}</span>
          </div>
        </div>
        <div class="play">
          <div v-show="courseDetailInfo.rid !== ''" class="play-btn" @click="handlePlay()">开始学习</div>
          <p class="play-p"><template>
              <span style="margin:0 20px" v-if="!isCollect" @click="handleCollect('on')"> <img class="c-star"
                  src="../assets/img/new/collect-gray.png" alt=""> 收藏</span>
              <span style="color: #25c0a7; margin:0 20px" v-else @click="handleCollect('off')"><img class="c-star"
                  src="../assets/img/new/collect.png" alt="">已收藏</span>
            </template>
            <!-- <template>
              <span v-if="!isLike" @click="handleLike('on')"><i class="el-icon-thumb c-star"></i>点赞</span>
              <span v-else @click="handleLike('off')" style="color: #25c0a7;"><i
                  class="el-icon-thumb c-star"></i>已点赞</span>
            </template> -->
          </p>

        </div>
      </div>
    </div>
    <!-- 课程大纲/评论/推荐课程 -->
    <div class="comment-intro-box">
      <div class="tab-box">
        <ul class="tab-block">
          <li :class="['tab-item', tabIndex === index ? 'tab-item-active' : '']" v-for="(item, index) in tabList"
            :key="index" @click="tabIndex = index">{{ item.name }}</li>
        </ul>
      </div>
      <div class="comment-intro-block">
        <div class="comment-box">
          <template v-if="tabIndex === 0">
            <div class="course-detail-box">
              <h3>课程简介</h3>
              <p v-html="courseDetailInfo.synopsis"></p>
            </div>
          </template>
          <template v-else>
            <div class="course-wrap-box">

              <el-collapse  >
                <el-collapse-item v-for="(item, index) in courseDetailInfo.videoOutlineList"   style="margin-top: 10px;"
                  :title="`0${index + 1}.` + item.title" :name="index" :key="index">
                  <div v-for="(c, i) in item.videoOutineDetails" :key="i" @click="handleOutLinePlay(c)"><img
                      src="../assets/img/video.png" alt="" style="width: 16px;height: 16px;"> {{ `${index + 1}.${i + 1}` +
                        c.name }}</div>
                </el-collapse-item>
              </el-collapse>

            </div>
          </template>


          <!-- 评论 -->
          <!-- <template v-if="tabIndex === 1">
            <div class="c-head">
              <div class="c-tag">全部</div>
              <div class="c-send-btn" @click="dialogVisible = true">我要发布</div>
            </div>
            <div v-if="commentList && commentList.length">
              <ul class="commit-list">
                <li class="commit-item" v-for="(item, index) in commentList" :key="index">
                  <div class="avatar">
                    <img :src="item.avatar" alt="">
                  </div>
                  <div class="r-box">
                    <div class="tit-box">
                      <p class="name">{{ item.nickname }}</p>
                      <span class="time">{{item.datetime}}</span>
                    </div>
                    <p class="tit">{{ item.commit }}</p>
                  </div>
                </li>
              </ul>
              <div class="pagination">
                <el-pagination @current-change="handleCurrentChange" :current-page="+params.page" :page-size="10" layout="total, prev, pager, next, jumper" :total="total">
                </el-pagination>
              </div>
            </div>
            <div class="no-more-data" v-else>
              <i class="el-icon-warning"></i>
              <span>目前暂无任何评论</span>
            </div>
          </template> -->
        </div>
        <div class="intro-box">
          <p><img src="../assets/img/courseDetail.png" style="width: 20px;height: 20px;"> <span>推荐课程</span> </p>
          <p></p>
          <ul>
            <li v-for="(item, index) in courseDetailInfo.commendVideoList" :key="index" @click="handleItem(item)">
              <div class="c-img">
                <img v-if="item.cover" :src="`/video-dev${item.cover}`" alt="">
              </div>
              <div class="c-type-box">
                <p class="c-tit">{{ item.title }}</p>
                <p class="view-p-count">
                  <!-- <i class="el-icon-user"></i> -->
                  <span> 讲师：{{ item.lecturer }}</span>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <el-dialog title="我要评论" :visible.sync="dialogVisible" :close-on-click-modal="false" :close-on-press-escape="false"
      width="40%">
      <el-input type="textarea" maxlength="300" show-word-limit :autosize="{ minRows: 5, maxRows: 5 }" placeholder="请输入内容"
        v-model="textareaVal">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="dialogVisible = false">发表评论</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { getVideoInfo, toggleCollect, addUserVideoLike, addUserView } from '@/axios/course'
import { getUserCollectList } from '@/axios/user'
import { Loading } from 'element-ui';
export default {
  name: 'mdCourseDetailPagesComponent',
  data() {
    return {
      tabIndex: 1,
      tabList: [
        {
          name: '课程大纲'
        }
      ],
      playbackRate: 1, // 播放倍速 1
      isCollect: false,
      isLike: false,
      drawFlag: true,
      dialogVisible: false,
      textareaVal: '',
      courseDetailInfo: {
        rid: '',
        isCollect: false, // 是否收藏
        title: '', // 标题
        synopsis: '', // 简介
        lecturer: '', // 讲师
        lecturerAvatar: '', // 讲师头像
        videoOutlineList: [], // 课程大纲
        commendVideoList: [] // 推荐课程
      },
      commentList: [],
      defaultExpandedKeys: [],
      params: {
        page: 1
      },
      total: 5
    }
  },
  watch: {
    '$route.query': {
      handler() {
        this.getVideoInfo()
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    userInfo() {
      return JSON.parse(window.localStorage.getItem('userInfo'))
    }
  },
  created() {
    this.$store.state.fixed = false
    this.courseTitle = this.$route.query.title || ''
  },
  methods: {
    async getUserCollectListDta(data) {
      const res = await getUserCollectList({ pageNum: 1, pageSize: 10, videoTitle: data.title })
      if (res.code == 200) {
        if (res.total !== 0) {
          this.isCollect = true

        }
      }
    },
    async getVideoInfo() {
      let loadingInstance1 = Loading.service({ fullscreen: true });
      const rid = this.$route.query.rid || ''
      const sysVideoId=this.$route.query.sysVideoId || ''
      const res = await getVideoInfo(rid, null,sysVideoId)
      if (res.code === 200) {
        loadingInstance1.close();
        this.courseDetailInfo = res.data
        this.getUserCollectListDta(res.data)
        this.videoOutlineList = res.data.videoOutlineList.map(item => {
          return { ...item, name: item.title }
        })

      }
    },
    async addUserView() {
      const res = await addUserView({ userId: this.userInfo.userId, deptID: this.userInfo.deptId, title:this.courseDetailInfo.title,videoId:this.courseDetailInfo.rid,cover:this.courseDetailInfo.cover,categoryId:this.courseDetailInfo.categoryId })
     console.log(res);
    },
    handlePlay() {
      console.log(this.courseDetailInfo,'this.courseDetailInfo');
      this.addUserView()
      let routeData = this.$router.resolve({
        path: '/video',
        query: {
          rid: this.courseDetailInfo?.rid,
          sysVideoId: this.$route.query.sysVideoId || ''
          // outLineId: this.courseDetailInfo?.videoOutlineList && this.courseDetailInfo?.videoOutlineList[0]?.videoOutineDetails[0]?.rid,
          // vUrl: this.courseDetailInfo?.videoOutlineList && this.courseDetailInfo?.videoOutlineList[0]?.videoOutineDetails[0]?.url
        }
      })
      window.open(routeData.href, '_blank');
    },
    handleOutLinePlay(item) {
      let routeData = this.$router.resolve({
        path: '/video',
        query: {
          rid:this.$route.query.rid,
          outLineId: item.rid,
          vUrl: item.url
        }
      })
      window.open(routeData.href, '_blank');
    },

    async handleCollect(type) {
      const user = JSON.parse(window.localStorage.getItem('userInfo'))
      if (user) {
        if (type === 'on') {
          this.isCollect = true
          const res = await toggleCollect({
            videoId: this.courseDetailInfo.rid
          })
          if (res.code === 200) {
            this.$message.success('收藏成功')
          }
        } else {
          this.isCollect = false
          const res = await toggleCollect({
            videoId: this.courseDetailInfo.rid
          })
          if (res.code === 200) {
            this.$message.success('取消收藏')
          }
        }
      } else {
        this.$message.error('请先登录')
      }


    },
    async handleLike(type) {
      const user = JSON.parse(window.localStorage.getItem('userInfo'))
      if (user) {
        if (type === 'on') {
          this.isLike = true
          const res = await addUserVideoLike({
            videoId: this.courseDetailInfo.rid
          })
          if (res.code === 200) {
            this.$message.success('点赞成功')
          }
        } else {
          this.isLike = false
          const res = await addUserVideoLike({
            videoId: this.courseDetailInfo.rid
          })
          if (res.code === 200) {
            this.$message.success('取消点赞')
          }
        }
      } else {
        this.$message.error('请先登录')
      }
    },
    handleCurrentChange(val) {
      this.params.page = val
    },
    handleItem(item) {
      this.$router.replace({
        path: '/courseDetail',
        query: {
          rid: item.rid
        }
      })
    },
  },
  beforeDestroy() {
    this.$store.state.fixed = true
  }
}
</script>
<style lang="less" scoped>
.md-course-detail-pages-component {
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 30px;
  // margin-top: 100px;

  .course-info-box {
    width: 100%;
    max-width: 1920px;
    height: 240px;
    background: url('../assets/img/new/video-banner.png') no-repeat center top #000;
    background-size: cover;
    padding: 16px 0;

    .course-info-con {
      width: 1152px;
      margin: 0 auto;

      .menu-box {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 20px;


      }

      .course-title {
        margin-bottom: 8px;
        line-height: 48px;
        font-size: 32px;
        color: #fff;
        font-family: PingFangSC, PingFang SC;
        font-weight: 500;

      }

      .teacher-box {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .t-info {
          display: flex;
          align-items: center;

          .avatar-box {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            overflow: hidden;
            margin-right: 10px;

            img {
              width: 100%;
              height: 100%;
            }
          }

          span {
            font-size: 14px;
            line-height: 22px;
            font-family: PingFangSC, PingFang SC;
            font-weight: 400;
            color: #FFFFFF;
            line-height: 20px;
          }
        }

        p {
          color: #fff;
          margin-left: 50px;

          span {
            display: inline-block;
            width: 71px;
            height: 32px;
            margin-right: 20px;
            font-size: 12px;

          }
        }


      }

      .play {
        display: flex;
        align-items: center;
        margin-top: 20px;

        .play-p {
          display: flex;
          align-items: center;

          span {
            cursor: pointer;
            color: #fff;
            background-color: rgba(255, 255, 255, 0.2);
            display: inline-block;
            padding: 6px 15px;
            border-radius: 16px;
            display: inline-block;
            display: flex;
            align-items: center;
            justify-content: center;


            .c-star {
              font-size: 14px;
              margin-right: 3px;
            }
          }
        }
      }

      .play-btn {
        color: #fff;
        width: 124px;
        height: 40px;
        background: @md-primary-color;
        padding: 10px 30px;
        font-size: 14px;
        border-radius: 34px;
        cursor: pointer;
      }
    }
  }

  .comment-intro-box {
    width: 100%;

    b {
      color: @md-primary-color;
    }

    .tab-box {
      width: 100%;
      height: 68px;
      background: #fff;
      box-shadow: 0 4px 8px 0 rgba(28, 31, 33, 0.1);

      .tab-block {
        width: 1152px;
        height: 68px;
        margin: 0 auto;
        display: flex;
        align-items: center;

        .tab-item {
          position: relative;
          float: left;
          margin-right: 96px;
          font-size: 16px;
          color: #333;
          line-height: 40px;
          cursor: pointer;
          font-weight: 700;

          &:hover {
            color: @md-primary-color;

            &::before {
              background: @md-primary-color;
            }
          }

          &::before {
            content: ' ';
            position: absolute;
            width: 20px;
            height: 3px;
            background: transparent;
            bottom: 0;
            margin-left: -10px;
            left: 50%;
            border-radius: 2px;
          }
        }

        .tab-item-active {
          color: @md-primary-color;

          &::before {
            background: @md-primary-color;
          }
        }
      }
    }

    .comment-intro-block {
      width: 1152px;
      margin: 20px auto 0;
      display: flex;
      background: #fff;

      .course-detail-box {
        margin-bottom: 20px;
        width: 844px;
        padding: 24px 32px 32px;
        background: #fff;
        box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.1);
        border-radius: 12px;
        box-sizing: border-box;

        h3 {
          margin-bottom: 10px;
        }

        p {
          line-height: 1.5;
          color: #1c1f21;
          text-indent: 2em;
        }
      }

      .course-wrap-box {
        width: 844px;
        padding: 24px 32px;
        background: #fff;
        box-shadow: 0 8px 16px 0 rgba(7, 17, 27, 0.1);
        border-radius: 12px;
        box-sizing: border-box;

        h3 {
          margin-bottom: 10px;
        }

        .course-list {
          .course-item {
            margin-bottom: 10px;

            h3 {
              color: #1c1f21;
              font-size: 16px;
              font-weight: 700;
              line-height: 24px;
              margin-bottom: 5px;
            }

            .course-tit {
              color: #333;
              font-size: 12px;
              margin-bottom: 10px;
            }

            .course-chapters {
              .chapter {
                cursor: pointer;
                color: #1c1f21;
                font-size: 14px;
                height: 48px;
                line-height: 48px;
                padding-left: 10px;
                box-sizing: border-box;

                &:hover {
                  background: rgba(37, 192, 167, 0.05);
                  border-radius: 4px;
                  color: @md-primary-color;
                }
              }
            }
          }
        }
      }

      .comment-box {
        width: 884px;
        margin-right: 15px;
        height: 100%;

        .c-head {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          padding-right: 10px;
          box-sizing: border-box;

          .c-tag {
            padding: 4px 12px;
            font-size: 12px;
            color: #545c63;
            line-height: 16px;
            background: #fff;
            border-radius: 12px;
            cursor: pointer;
            opacity: 0;
          }

          .c-send-btn {
            padding: 7px 16px;
            font-size: 14px;
            line-height: 1.42857143;
            border-radius: 18px;
            color: #545c63;
            cursor: pointer;
            background: rgba(84, 92, 99, 0.1);
          }
        }

        .commit-list {
          margin-top: 20px;
          width: 100%;
          padding: 15px 10px 0;
          overflow-y: auto;
          .scroll-bar(5px, 5px);

          .commit-item {
            // margin-bottom: 15px;
            width: 100%;
            display: flex;
            padding: 15px 10px;
            box-sizing: border-box;
            border-bottom: 1px solid #ededed;

            // margin-bottom: 10px;
            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .avatar {
              width: 40px;
              height: 40px;
              border-radius: 50%;
              overflow: hidden;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
              }
            }

            .r-box {
              margin-left: 10px;
              flex: 1;

              .tit-box {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 4px;

                .name {
                  font-size: 14px;
                  color: #333;
                }

                .time {
                  font-size: 12px;
                  color: #888;
                }
              }

              .tit {
                font-size: 12px;
                color: #555;
              }
            }
          }
        }
      }

      .intro-box {
        width: 336px;
        flex-shrink: 0;
        margin-bottom: 30px;
        border-radius: 12px;
        box-shadow: 0 4px 8px 0 rgba(95, 101, 105, 0.1);
        position: relative;

        >:nth-child(1) {
          padding: 20px 0 12px 0px;
          font-size: 16px;
          font-family: PingFangSC, PingFang SC;
          font-weight: 500;
          color: #333333;
          line-height: 22px;
          margin: 0 20px;
          display: flex;
          align-items: center;
          border-bottom: 1px solid #E6E6E6;
        }

        >:nth-child(2) {
          position: absolute;
          top: 54px;
          width: 98px;
          height: 2px;
          background-color: #0FB09B;
          margin: 0 20px;
        }

        ul {
          margin-top: 10px;
          // background: #f8fafc;
          // padding: 5px;
          box-sizing: border-box;

          li {
            display: flex;
            margin-bottom: 12px;
            padding: 10px 20px;
            border-radius: 4px;
            position: relative;

            &:last-child {
              margin-bottom: 0;
              border-bottom: none;
            }

            .c-img {
              width: 135px;
              height: 90px;
              border-radius: 6px;
              overflow: hidden;
              position: relative;
              flex-shrink: 0;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }
            }

            .c-type-box {
              flex: 1;
              height: 60px;
              margin-left: 20px;
              overflow: hidden;

              .c-tit {
                width: 100%;
                padding-top: 8px;
                font-size: 14px;
                font-size: 16px;
                margin-bottom: 4px;
                font-size: 600;
                cursor: pointer;
                overflow: hidden;
                text-overflow: ellipsis;
                -webkit-line-clamp: 2;
                display: -webkit-box;
                -webkit-box-orient: vertical;
              }

              .view-p-count {
                display: flex;
                align-items: center;
                color: #545c63;
                font-size: 12px;
                margin-top: 5px;
                position: absolute;
                bottom: 10px;

                i {
                  margin-right: 3px;
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
    }
  }

  .no-more-data {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    i {
      font-size: 30px;
      color: #9199a1;
      margin-bottom: 5px;
    }

    span {
      font-size: 16px;
      color: #9199a1;
      line-height: 24px;
    }
  }

  @keyframes load-effect {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
}

/deep/ .el-breadcrumb__item:last-child .el-breadcrumb__inner,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a,
.el-breadcrumb__item:last-child .el-breadcrumb__inner a:hover,
.el-breadcrumb__item:last-child .el-breadcrumb__inner:hover {
  color: rgba(255, 255, 255, 0.5);
}

/deep/ .el-breadcrumb__item {
  .el-breadcrumb__inner a,
  .el-breadcrumb__inner.is-link {
    color: rgba(255, 255, 255, 0.5);
    font-weight: normal;

    &:hover {
      color: @md-primary-color;
    }
  }
}

/deep/ .el-button--primary {
  background: #f20d0d;
  border-color: #f20d0d;
}

.pagination {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

/deep/ .el-pager li.active {
  color: #f20d0d;
}

/deep/ .el-pager li:hover {
  color: #f20d0d;
}

/deep/ .el-input__inner:focus {
  border-color: #f20d0d !important;
}

/deep/ .el-pagination button:hover {
  color: #f20d0d;
}



::v-deep {
  .el-collapse-item__header {
    background-color: #F5F7F7;
    padding-left: 16px;
    border-radius: 8px;
    font-weight: 600;
    font-size: 16px;
  }

  .el-collapse-item__content {
    cursor: pointer;
    font-size: 14px;
    padding: 8px;
    padding-left: 16px;
  }
}
</style>
